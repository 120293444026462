import React from 'react'
import Historia from '../Components/Acerca/Historia/Historia';
import Vision from '../Components/Acerca/Vision/Vision';
import Mision from '../Components/Acerca/Mision/Mision';
import Equipo from '../Components/Inicio/EquipoMesafarq/EquipoMesafarq';

const Acerca = () => {
  return (
    <div>
      <Historia/>
      <Vision/>
      <Mision/>
      {/*<Equipo/>*/}
    </div>

  )
}

export default Acerca