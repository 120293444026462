import React from 'react'
import Catalogo from '../Components/Productos/Catalogo/Catalogo';


const Productos = () => {
  return (
    <div>
      <Catalogo/>
    </div>
  )
}

export default Productos
